import timeout from './timeout';
import categoryCodes from '../../common/settings/categoryCodes';
import applicationComplete from './applicationComplete';
import iconColours from './iconColours';
import icons from './icons';

export default {
  timeout: timeout,
  categoryCodes: categoryCodes,
  applicationComplete: applicationComplete,
  icons: icons,
  iconColours: iconColours,
};
